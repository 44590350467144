


























































import Vue from "vue";
import { Form } from "element-ui";
import { saveOrUpdate } from "@/api/advert";
import upload from "@/views/course/components/CourseImage.vue";

export default Vue.extend({
  name: "ResourceAddOrUpdate",
  components: {
    upload
  },
  props: {
    ggwlist: {
      type: Array
    }
  },
  data() {
    return {
      selectList: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入广告名称", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择开始时间", trigger: "blur" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "blur" }],
        link: [{ required: true, message: "请输入广告链接", trigger: "blur" }]
      },
      typeList: [],
      dialogFormVisible: false
    };
  },
  methods: {
    add() {
      this.form = {
        name: ""
      };
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        (this.$refs.ruleForm as Form).clearValidate();
      });
    },
    editor(row: any) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        (this.$refs.ruleForm as Form).clearValidate();
      });
    },
    submit() {
      (this.$refs.ruleForm as Form).validate(async (valid) => {
        if (valid) {
          const { data } = await saveOrUpdate(this.form);
          if (data.state === 1) {
            this.$message.success("保存成功");
            this.dialogFormVisible = false;
            this.$emit("ok");
          } else {
            this.$message.error(data.message);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
});
