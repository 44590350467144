
























































import Vue from "vue";
import create from "./components/create.vue";
import { getList, updateStatus } from "@/api/advert";
import { getList as ggwList } from "@/api/advert-space";
import moment from "moment";

export default Vue.extend({
  name: "AdvertSpaceIndex",
  filters: {
    timeFilter(value: string) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  components: {
    create
  },
  computed: {
    ggw() {
      return (id: number) => {
        const result = (this.ggwlist as any).find((item: any) => item.id === id);
        return result ? result.name : "";
      };
    }
  },
  data() {
    return {
      form: {},
      typeList: [],
      list: [],
      ggwlist: []
    };
  },
  created() {
    this.getList();
    this.getGgwList();
  },
  methods: {
    indexMethod(index: number) {
      return index + 1;
    },
    statusChange(status: number, id: number) {
      const message = status === 1 ? "确认上线吗？" : "确认下线吗？";
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await updateStatus({
            status,
            id
          });
          if (data.state === 1) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(data.message);
          }
        })
        .catch(() => {
          // 取消执行这里
        });
    },
    async getList() {
      const { data } = await getList();
      if (data.state === 1) {
        this.list = data.content;
      } else {
        this.$message.error(data.mesg);
      }
    },
    async getGgwList() {
      const { data } = await ggwList();
      if (data.state === 1) {
        this.ggwlist = data.content;
      } else {
        this.$message.error(data.mesg);
      }
    }
  }
});
